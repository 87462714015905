import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { RouterLink } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { CapitalizeInitialsPipe } from './pipes/capitalize-initials.pipe';
import { InfoboxComponent } from './components/infobox/infobox.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PhonePipe } from './pipes/phone.pipe';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HoverModalInformationDealComponent } from '@shared/components/hover-modal/hover-modal-information-deal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    CapitalizeFirstLetterPipe,
    CapitalizeInitialsPipe,
    InfoboxComponent,
    NavbarComponent,
    PaginationComponent,
    PhonePipe,
    SidebarComponent,
    HoverModalInformationDealComponent,
  ],
  imports: [
    RouterLink,
    CommonModule,
    PaginationModule,
    CollapseModule,
    BsDropdownModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [
    CapitalizeFirstLetterPipe,
    CapitalizeInitialsPipe,
    InfoboxComponent,
    NavbarComponent,
    PaginationComponent,
    PhonePipe,
    SidebarComponent,
  ],
})
export class SharedModule {}
