<div class="row">
  <div class="col-xs-12 col-12 d-flex justify-content-center">
    <pagination
      (pageChanged)="toSkip($event)"
      [boundaryLinks]="true"
      [maxSize]="maxSize"
      [rotate]="rotate"
      [totalItems]="total"
      firstText="&laquo;"
      lastText="&raquo;"
      nextText="&rsaquo;"
      previousText="&lsaquo;"
    ></pagination>
  </div>
</div>
