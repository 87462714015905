import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  getCountries() {
    return this.http.get(`${environment.API_URI}/country`);
  }
}
