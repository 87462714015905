import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env/environment';
import { MailModel } from '@core/models';
import { AuthService } from '@core/providers/auth.service';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(AuthService) private authService: AuthService,
  ) {}

  sendMail(data: MailModel) {
    const headers = new HttpHeaders({
      Authorization: this.authService.getToken().token,
      'Content-Type': 'application/json',
    });

    return this.http.post(`${environment.API_URI}/mail/send`, data, {
      headers,
    });
  }
}
