import { Component, OnInit } from '@angular/core';

import { AuthService } from '@core/providers';
import { companyName, logo } from '@env/environment';
import { RouteInfo } from './models/router-info.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: `./sidebar.component.html`,
})
export class SidebarComponent implements OnInit {
  menuItems: RouteInfo[];
  logo = logo;
  company = companyName;

  constructor(public auth: AuthService) {}

  ngOnInit() {
    this.createMenu();
  }

  authRole(role: string[]): boolean {
    return role.includes(this.auth.getToken().user?.role);
  }

  createMenu() {
    this.menuItems = [
      {
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        iconType: 'tim-icons icon-chart-pie-36',
        permission: ['SUPER_ADMIN', 'ADMIN', 'USER'],
      },
      {
        path: '/mail',
        title: 'Mails',
        type: 'link',
        iconType: 'tim-icons icon-email-85',
        permission: ['SUPER_ADMIN', 'ADMIN'],
      },
      {
        path: '/deals',
        title: 'Deals',
        type: 'sub',
        iconType: 'tim-icons icon-paper',
        collapse: 'deals',
        isCollapsed: true,
        children: [
          {
            path: 'all',
            title: 'List',
            type: 'link',
            smallTitle: 'DL',
            permission: ['SUPER_ADMIN', 'ADMIN', 'USER'],
          },
        ],
      },
      {
        path: '/companies',
        title: 'Companies',
        type: 'sub',
        iconType: 'tim-icons icon-badge',
        collapse: 'companies',
        isCollapsed: true,
        children: [
          {
            path: 'all',
            title: 'List',
            type: 'link',
            smallTitle: 'CL',
            permission: ['SUPER_ADMIN', 'ADMIN', 'USER'],
          },
          {
            path: 'blacklist',
            title: 'Blacklist',
            type: 'link',
            smallTitle: 'CB',
            permission: ['SUPER_ADMIN', 'ADMIN', 'USER'],
          },
        ],
      },
      {
        path: '/banks',
        title: 'Banks',
        type: 'link',
        iconType: 'tim-icons icon-bank',
        permission: ['SUPER_ADMIN', 'ADMIN'],
      },
      {
        path: '/bankslists',
        title: 'Banks Lists',
        type: 'link',
        iconType: 'tim-icons icon-notes',
        permission: ['SUPER_ADMIN', 'ADMIN'],
      },
      {
        path: '/users/all',
        title: 'Users',
        type: 'link',
        iconType: 'tim-icons icon-single-02',
        permission: ['SUPER_ADMIN'],
      },
    ];
  }
}
