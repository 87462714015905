import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@core/providers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    req = req.clone();

    let token: string;
    if (this.auth.isLogged()) {
      token = this.auth.getToken().token;
    }

    let contentType = FormData.prototype.isPrototypeOf(req.body)
      ? 'multipart/form-data'
      : 'application/json; charset=utf-8';

    let headers: HttpHeaders;

    if (token) {
      if (FormData.prototype.isPrototypeOf(req.body)) {
        headers = req.headers.set('Content-Type', 'multipart/form-data');
        headers = req.headers.set('Authorization', `Bearer ${token}`);
      } else {
        headers = req.headers.set(
          'Content-Type',
          'application/json; charset=utf-8',
        );
        headers = req.headers.set('Authorization', `Bearer ${token}`);
      }
    } else {
      headers = req.headers.set(
        'Content-Type',
        'application/json; charset=utf-8',
      );
    }

    req = req.clone({ headers });
    return next.handle(req).pipe(
      tap(
        (event: any) => {
          if (event instanceof HttpResponse) {
            //bien
          }
        },
        (err: any) => {
          if (err.status === 401) {
            this.auth.logOut();
            this.router.navigate(['/auth/login']);
          } else if (err.status === 403) {
            this.toastr.error('Forbbiden', 'No tiene permisos');
            this.router.navigate(['/auth/403']);
          }
        },
      ),
    );
  }
}
