import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  getStates(countryId: string) {
    const params = new HttpParams().set('countryId', countryId);
    return this.http.get(`${environment.API_URI}/states`, { params });
  }

  getAutoCompleteStates(state: string) {
    const params = new HttpParams().set('state', state);
    return this.http.get(`${environment.API_URI}/states/autocomplete/state`, {
      params,
    });
  }
}
