import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';
import { CreateDealInterface } from '@deals/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DealService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  getDeals(query?: any) {
    let options = {};

    if (query) {
      let params = new HttpParams()
        .set('skip', query.skip.toString())
        .set('limit', query.limit.toString());

      if (query.companyId !== '') {
        params = params.set('companyId', query.companyId);
      }
      if (query.period !== '') {
        params = params.set('period', query.period);
      }
      if (query.insertBy !== '') {
        params = params.set('insertBy', query.insertBy);
      }
      if (query.status !== '') {
        params = params.set('status', query.status);
      }

      options = { params };
    }

    return this.http.get(`${environment.API_URI}/deals`, options);
  }

  addBanksToDeal(data: { dealId: string; banksIds: []; message: string }) {
    return this.http.patch(`${environment.API_URI}/deals/addBank`, data);
  }

  sendBanks(payload: { dealId: string; banksIds: string[]; message: string }) {
    return this.http.patch(`${environment.API_URI}/deals/send/bank`, payload);
  }

  addHistoryBank(data: {
    dealId: string;
    bankId: string;
    status: string;
    amount: number;
    comment: string;
  }) {
    return this.http.patch(`${environment.API_URI}/deals/addHistoryBank`, data);
  }

  updateDeal(data: { dealId: string; status: string }) {
    return this.http.patch(`${environment.API_URI}/deals`, data);
  }

  createDeal(deal: CreateDealInterface) {
    return this.http.post(`${environment.API_URI}/deals`, deal);
  }
}
