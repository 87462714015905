import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@core/providers';
import { appVersion } from '@env/environment';

const misc: any = { sidebar_mini_active: true };

@Component({
  selector: 'app-root',
  templateUrl: `./app.component.html?v=${appVersion}`,
  styles: [],
})
export class AppComponent implements OnInit {
  hidden: number = 1;

  constructor(
    public auth: AuthService,
    public router: Router,
  ) {}

  @HostListener('window:scroll', ['$event'])
  ngOnInit() {
    this.showNavbarButton();
  }

  showNavbarButton = () => {
    const navbarMinimize: any = document.getElementsByClassName(
      'navbar-minimize-fixed',
    )[0];

    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50
    ) {
      this.hidden = 1;
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50
    ) {
      this.hidden = 0;
    }
  };

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add('sidebar-mini');
      misc.sidebar_mini_active = true;
    }
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
}
