<div *ngIf="auth.isLogged(); else login" class="wrapper">
  <div
    (click)="minimizeSidebar()"
    [ngStyle]="{ opacity: hidden }"
    class="navbar-minimize-fixed d-inline"
  >
    <button
      class="minimize-sidebar btn btn-link btn-just-icon"
      placement="right"
    >
      <i class="tim-icons icon-align-center visible-on-sidebar-regular"> </i>
      <i class="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"> </i>
    </button>
  </div>
  <div class="sidebar" data="primary">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main-panel" data="primary">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
  </div>
</div>
<ng-template #login>
  <div class="vh-100">
    <div class="align-self-center">
      <div class="auth-page">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-template>
