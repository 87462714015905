import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from './auth.service';
import { environment } from '@env/environment';
import { createFormData } from '../../app/utils';
import { InvoiceFilterModel } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class CommissionsService {
  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(AuthService) private AuthService: AuthService,
  ) {}

  getAll(filters: InvoiceFilterModel) {
    const query = createFormData(filters);
    const headers = new HttpHeaders({
      Authorization: this.AuthService.getToken().token,
      accept: 'application/json',
    });

    return this.http.get(`${environment.API_URI}/invoice?${query}`, {
      headers,
    });
  }

  create(data) {
    const headers = new HttpHeaders({
      Authorization: this.AuthService.getToken().token,
      accept: 'application/json',
    });
    return this.http.post(
      `${environment.API_URI}/invoice`,
      { ...data },
      { headers },
    );
  }

  getById(id: number) {
    const headers = new HttpHeaders({
      Authorization: this.AuthService.getToken().token,
      accept: 'application/json',
    });
    return this.http.get(`${environment.API_URI}/invoice/${id}`, {
      headers,
    });
  }
}
