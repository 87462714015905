import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AdminGuard, AuthGuard, IsActiveGuard } from './guards';

export const Routing: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./website/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },
  {
    path: 'auth',
    canActivate: [IsActiveGuard],
    loadChildren: () =>
      import('./website/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'deals',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./website/deals/deals.module').then((m) => m.DealsModule),
  },
  {
    path: 'bankslists',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./website/banklists/bank-lists.module').then(
        (m) => m.BankListsModule,
      ),
  },
  {
    path: 'banks',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./website/banks/banks.module').then((m) => m.BanksModule),
  },
  {
    path: 'companies',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./website/companies/companies.module').then(
        (m) => m.CompaniesModule,
      ),
  },
  {
    path: 'users',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./website/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'mail',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./website/mails/mails.module').then((m) => m.MailsModule),
  },
  // {
  //     path: 'services',
  //     canActivate: [AuthGuard, AdminGuard],
  //     loadChildren: () => import('./website/customized-services/customized-services.module').then(m => m.CustomizedServicesModule)
  // },
  { path: '**', pathMatch: 'full', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(Routing)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
