import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from './auth.service';
import { environment } from '@env/environment';
import { CityCreate, Sites, StateCreate } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(AuthService) private AuthService: AuthService,
  ) {}

  getCountries(): Promise<Sites> {
    const headers = new HttpHeaders({
      Authorization: this.AuthService.getToken().token,
      accept: 'application/json',
    });
    return new Promise<Sites>((resolve, reject) => {
      this.http
        .get(`${environment.API_URI}/country`, { headers })
        .toPromise()
        .then((res) => {
          resolve(res as Sites);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  getStates(country_id): Promise<Sites> {
    const headers = new HttpHeaders({
      Authorization: this.AuthService.getToken().token,
      accept: 'application/json',
    });
    return new Promise<Sites>((resolve, reject) => {
      this.http
        .get(`${environment.API_URI}/states/?idCountry=${country_id}`, {
          headers,
        })
        .toPromise()
        .then((res) => {
          resolve(res as Sites);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  getCities(state_id) {
    const headers = new HttpHeaders({
      Authorization: this.AuthService.getToken().token,
      'Content-Type': 'application/json',
    });
    return new Promise<Sites>((resolve, reject) => {
      this.http
        .get(`${environment.API_URI}/cities/?idState=${state_id}`, {
          headers,
        })
        .toPromise()
        .then((res) => {
          resolve(res as Sites);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  createCity(data: CityCreate) {
    const headers = new HttpHeaders({
      Authorization: this.AuthService.getToken().token,
      'Content-Type': 'application/json',
    });
    return new Promise<Sites>((resolve, reject) => {
      this.http
        .post(`${environment.API_URI}/cities`, data, { headers })
        .toPromise()
        .then((res) => {
          resolve(res as Sites);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  createState(data: StateCreate) {
    const headers = new HttpHeaders({
      Authorization: this.AuthService.getToken().token,
      'Content-Type': 'application/json',
    });
    return new Promise<Sites>((resolve, reject) => {
      this.http
        .post(`${environment.API_URI}/states`, data, { headers })
        .toPromise()
        .then((res) => {
          resolve(res as Sites);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }
}
