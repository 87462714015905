<nav
  [ngClass]="{
    'bg-white': isCollapsed === false,
    'navbar-transparent': isCollapsed === true
  }"
  class="navbar navbar-expand-lg navbar-absolute"
>
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <button
          (click)="minimizeSidebar()"
          class="minimize-sidebar btn btn-link btn-just-icon"
          placement="right"
          tooltil="Sidebar toggle"
        >
          <i class="tim-icons icon-align-center visible-on-sidebar-regular">
          </i>
          <i class="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"> </i>
        </button>
      </div>
      <div class="navbar-toggle d-inline">
        <button (click)="sidebarOpen()" class="navbar-toggler" type="button">
          <span class="navbar-toggler-bar bar1"> </span>
          <span class="navbar-toggler-bar bar2"> </span>
          <span class="navbar-toggler-bar bar3"> </span>
        </button>
      </div>
      <a class="navbar-brand" href="javascript:void(0)"> Dashboard </a>
    </div>

    <button
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-label="Toggle navigation"
      class="navbar-toggler"
      id="navigation"
      type="button"
    >
      <span class="navbar-toggler-bar navbar-kebab"> </span>
      <span class="navbar-toggler-bar navbar-kebab"> </span>
      <span class="navbar-toggler-bar navbar-kebab"> </span>
    </button>
    <div [collapse]="isCollapsed" class="navbar-collapse" id="navigation">
      <ul class="navbar-nav ml-auto">
        <li class="dropdown nav-item" dropdown placement="bottom left">
          <a
            (click)="$event.preventDefault()"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            dropdownToggle
            href="#"
          >
            <div class="photo">
              <img
                *ngIf="this.auth.user?.picture"
                [src]="urlBaseFiles + this.auth.user?.picture"
                alt="Profile Photo"
              />
              <img
                *ngIf="!this.auth.user?.picture"
                alt="Profile Photo"
                src="https://crm-info.s3.amazonaws.com/crm-bmf/images-crm/perfil.png"
              />
            </div>
            <b class="caret d-none d-lg-block d-xl-block"> </b>
            <p class="d-lg-none">Log out</p>
          </a>
          <ul
            *dropdownMenu
            class="dropdown-menu dropdown-navbar dropdown-menu-right"
          >
            <li class="nav-link">
              <a
                [routerLink]="['/users/profile']"
                class="nav-item dropdown-item"
              >
                Profile
              </a>
            </li>
            <li class="dropdown-divider"></li>
            <li class="nav-link">
              <a (click)="disconnect()" class="nav-item dropdown-item">
                Log out
              </a>
            </li>
          </ul>
        </li>
        <li class="separator d-lg-none"></li>
      </ul>
    </div>
  </div>
</nav>
