import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { IStatement } from '@companies/interfaces';

@Injectable({
  providedIn: 'root',
})
export class StatementService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  getInformationFile(companyId: string) {
    return this.http.get(
      `${environment.API_URI}/statements?companyId=${companyId}`,
    );
  }

  getInformationFiles(companyId: string) {
    return this.http.get(
      `${environment.API_URI}/statements/all?companyId=${companyId}`,
    );
  }

  updateFileCompanies(data: FormData) {
    return this.http.patch(
      `${environment.API_URI}/statements/upload/file`,
      data,
    );
  }

  downloadFilePrivate(data: IStatement): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/pdf');

    const params = {
      statementId: data.statementId,
      typeStatement: data.typeStatement,
      documentId: data._id,
    };

    return this.http.get(`${environment.API_URI}/statements/download/file`, {
      headers,
      observe: 'response',
      responseType: 'blob',
      params,
    });
  }

  deleteDocument(data: any) {
    return this.http.patch(
      `${environment.API_URI}/statements/delete/file/`,
      data,
    );
  }

  UpdateStatements(data: any) {
    return this.http.patch(`${environment.API_URI}/statements`, data);
  }
}
