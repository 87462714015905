import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-hover-modal',
  templateUrl: './hover-modal-information-deal.component.html',
  styleUrls: ['./hover-modal-information-deal.component.scss'],
})
export class HoverModalInformationDealComponent {
  constructor(
    public dialogRef: MatDialogRef<HoverModalInformationDealComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { comment: string },
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
