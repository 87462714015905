<div
  *ngIf="info?.show"
  [ngClass]="info.class"
  class="alert alert-dismissible fade show"
  role="alert"
>
  <div>
    <ng-container *ngIf="info.message?.code == 11000; else message">
      Duplicate Entry
    </ng-container>
    <ng-template #message>
      {{ info.message }}
    </ng-template>
    <span *ngIf="!info?.persist">{{ countDown() }}</span>
  </div>
</div>
