import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-pagination',
  templateUrl: `./pagination.component.html`,
  styles: [],
})
export class PaginationComponent {
  @Input() total: number;
  @Input() limit: number;
  @Output() changePage: EventEmitter<number>;
  rotate = true;
  maxSize = 7;
  status = 'ON';

  constructor() {
    this.changePage = new EventEmitter();
  }

  toSkip(event: PageChangedEvent): void {
    this.changePage.emit(event.page * this.limit - this.limit);
    window.scroll(0, 0);
  }
}
