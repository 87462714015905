import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  getCities(stateId: string) {
    let params = new HttpParams().set('stateId', stateId);
    return this.http.get(`${environment.API_URI}/cities`, { params: params });
  }
}
