import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';
import { User } from '@users/interfaces/user.interface';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User;

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(AuthService) private AuthService: AuthService,
  ) {}

  getUsers(query?: any) {
    let options = {};

    if (query) {
      let params = new HttpParams()
        .set('skip', query.skip.toString())
        .set('limit', query.limit.toString())
        .set('isVerification', query.isVerification)
        .set('isActive', query.isActive);

      if (query.filter != '') {
        params = params.set('filter', query.filter);
      }

      options = { params };
    }
    return this.http.get(`${environment.API_URI}/user/search`, options);
  }

  getUsersByName(query?: any) {
    let options = {};

    if (query) {
      let params = new HttpParams().set('fullName', query.fullName);

      if (query.fullName != '') {
        params = params.set('fullName', query.fullName);
      }

      options = { params };
    }
    return this.http.get(`${environment.API_URI}/user/searchUsers`, options);
  }

  getUser(userId: string) {
    return this.http.get(`${environment.API_URI}/user/${userId}`);
  }

  userInformation() {
    return this.http.get(`${environment.API_URI}/user/information`);
  }

  createUser(user: User) {
    return this.http.post(`${environment.API_URI}/user`, user);
  }

  updateUser(user: User, id: string) {
    const headers = new HttpHeaders({
      Authorization: this.AuthService.getToken().token,
      'Content-Type': 'application/json',
    });
    return this.http.put(`${environment.API_URI}/users/${id}`, user, {
      headers,
    });
  }

  updateSuperUser(user: User) {
    return this.http.patch(`${environment.API_URI}/user/updateSuper`, user);
  }

  changePassword(password: string, id: string) {
    return this.http.put(`${environment.API_URI}/users/${id}/password`, {
      password: password,
    });
  }

  updatePhoto(id: string, file) {
    const upload = new FormData();
    upload.append('file', file, file.name);
    return this.http.put(`${environment.API_URI}/users/picture`, upload);
  }

  updatePicture(file: any) {
    const upload = new FormData();
    upload.append('file', file);
    return this.http.patch(`${environment.API_URI}/user/picture`, upload);
  }

  verifyUser(code: string, token: string) {
    return this.http.get(
      `${environment.API_URI}/users/verify?hash=${code}&token=${token}`,
    );
  }

  accountActivate(data: { token: string }) {
    return this.http.patch(`${environment.API_URI}/user/activateUser`, data);
  }

  resendEmailVerification(data: { userId: string }) {
    return this.http.post(
      `${environment.API_URI}/user/resend-verification-email`,
      data,
    );
  }

  // excepcion just to change password use this form to send token
  updatePassword(data: { password: string }, token: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    return this.http.patch(
      `${environment.API_URI}/user/update-password`,
      data,
      {
        headers,
      },
    );
  }
}
