import { FormGroup } from '@angular/forms';

export const PASSWORD_PATTERN = '/^(?=.*[A-Z])(?=.*[!@#$%^&*()_-+=<>?]).{9,}$/';

export function createFormData(obj: object) {
  const formData = [];
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (typeof obj[prop] === 'object' && Array.isArray(obj[prop])) {
        formData.push(
          `${prop}=${encodeURIComponent(JSON.stringify(obj[prop]))}`,
        );
      } else {
        formData.push(`${prop}=${encodeURIComponent(obj[prop])}`);
      }
    }
  }
  return formData.join('&');
}

export function errorHandler(content) {
  const dictionary = [
    // Unhandled
    {
      code: 'A000',
      persist: true,
      message:
        'The request could not be processed.\nSend the following content to technical support:\n\n' +
        encodeResp(content),
    },
    { code: 'A004', message: 'This entry already exists', persist: false },
    // Report
    { code: 'C001', message: 'Report not found' },
    { code: 'C002', message: 'This user already exist' },
    { code: 'C003', message: 'This report is already closed' },
    {
      code: 'C004',
      message:
        'This report cannot be closed, because there are not enough commissions',
    },
    // Auth
    { code: '0001', message: 'Invalid password or email' },
    { code: '0002', message: 'Account not activated' },
    { code: '0003', message: 'User is already verified' },
    { code: '0004', message: 'Invalid activation token' },
    { code: '0005', message: 'Code Expired' },
    { code: '0006', message: 'Expired token, log in again', persist: true },
    { code: '0007', message: 'Insufficient permissions', persist: true },
    // User
    { code: '1001', message: 'User not found' },
    { code: '1002', message: 'This user already exist' },
    // Banks
    { code: '3001', message: 'Bank not found' },
    // Bank Lists
    { code: 'B001', message: 'List not found' },
    // Calendar
    { code: '4001', message: 'Task not found' },
    // Company
    { code: '5001', message: 'Company not found' },
    { code: '5002', message: 'This code does not exist, or is already in use' },
    { code: '5003', message: 'This company is blacklisted' },
    // Deal
    { code: '6001', message: 'Deal not found' },
    { code: '6002', message: 'Deal is already closed' },
    { code: '6003', message: 'Missing statements for this period' },
    { code: '6004', message: 'No new bank has been added' },
    {
      code: '6005',
      message: 'This deal is blocked because it has an active period',
    },
    // Files
    { code: '7001', message: 'File not found in our server', persist: true },
    {
      code: '7002',
      message:
        'There is a file with this name, rename the file you are trying to upload and try again',
    },
    { code: '7003', message: 'Could not upload file' },
    { code: '7004', message: 'Invalid file' },
    // Featured Employee
    { code: '8001', message: 'Employee not found' },
    { code: '8002', message: 'Invalid Employee ID' },
    { code: '8003', message: 'Invalid image dimension' },
    // Postman
    { code: '9001', message: 'Mail not found' },
    { code: '9002', message: 'Could not send mails' },
    // Activities
    { code: 'E001', message: 'Activity not found' },
    // Contact List
    { code: 'F001', message: 'Contact List not found' },
    { code: 'F002', message: 'Contact not found' },
  ];

  let resp = dictionary[0];
  if (
    content.hasOwnProperty('error') &&
    content.error.hasOwnProperty('error')
  ) {
    const pos = dictionary
      .map((entry) => entry.code)
      .indexOf(content.error.error);
    if (pos !== -1) {
      resp = dictionary[pos];
    }

    if ((content.status === 401 || content.status === 403) && pos === -1) {
      resp.message = 'Insufficient permissions';
    }
  }

  if (content.status === 413) {
    resp.message = 'File too big';
  }

  if (content.status === 0) {
    resp.message = 'Could not connect to server';
    resp.persist = true;
  }

  if (content.status === 500) {
    resp.message = dictionary[0].message;
  }

  return resp;
}

function encodeResp(Obj) {
  return btoa(JSON.stringify(Obj));
}

export function generateRandomPassword(): string {
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const specialCharacters = '!@#$%^&*_+=';
  const allCharacters =
    'abcdefghijklmnopqrstuvwxyz' + uppercaseLetters + specialCharacters;

  let password = '';
  let hasUppercase = false;
  let hasSpecialChar = false;

  while (password.length < 9 || !hasUppercase || !hasSpecialChar) {
    const randomIndex = Math.floor(Math.random() * allCharacters.length);
    const randomChar = allCharacters[randomIndex];

    if (!hasUppercase && uppercaseLetters.includes(randomChar)) {
      hasUppercase = true;
    }

    if (!hasSpecialChar && specialCharacters.includes(randomChar)) {
      hasSpecialChar = true;
    }

    password += randomChar;
  }

  return password;
}

export function getValidationMessagesGeneral(
  formControl: string,
  form: FormGroup,
) {
  const control = form.get(formControl);
  const messages: string[] = [];

  if (control?.errors) {
    if (control.errors['required']) {
      messages.push('This field is required!');
    }
    if (control.errors['email']) {
      messages.push('Must be email!');
    }

    if (formControl === 'email') {
      if (control.errors['pattern']) {
        messages.push('Must be an email valid');
      }
    }
    if (formControl === 'password') {
      if (control.errors['pattern']) {
        messages.push('Minimum 8 characters, 1 Special character, 1 Capital');
      }
    }
  }

  return messages;
}
