<div class="sidebar-wrapper">
  <div class="logo">
    <a class="simple-text logo-mini" href="#/dashboard">
      <div class="logo-img">
        <img alt="logo" src="{{ logo }}" />
      </div>
    </a>
    <a class="simple-text logo-normal" href="#/dashboard">{{ company }}</a>
  </div>

  <ul class="nav">
    <li *ngFor="let menuitem of menuItems" routerLinkActive="active">
      <a
        *ngIf="menuitem.type === 'link' && authRole(menuitem.permission)"
        [routerLink]="[menuitem.path]"
      >
        <i class="{{ menuitem.iconType }}"></i>
        <p>{{ menuitem.title }}</p>
      </a>
      <a
        (click)="menuitem.isCollapsed = !menuitem.isCollapsed"
        *ngIf="menuitem.type === 'sub'"
        [attr.aria-controls]="menuitem.collapse"
        [attr.aria-expanded]="!menuitem.isCollapsed"
        data-toggle="collapse"
        routerLinkActive="active"
      >
        <i class="{{ menuitem.iconType }}"></i>
        <p>{{ menuitem.title }}<b class="caret"></b></p>
      </a>
      <div
        *ngIf="menuitem.type === 'sub'"
        [collapse]="menuitem.isCollapsed"
        [isAnimated]="true"
        class="collapse"
        id="{{ menuitem.collapse }}"
      >
        <ul class="nav">
          <li
            *ngFor="let childitems of menuitem.children"
            routerLinkActive="active"
          >
            <a
              *ngIf="
                childitems.type === 'link' && authRole(childitems.permission)
              "
              [routerLink]="[menuitem.path, childitems.path]"
            >
              <span class="sidebar-mini-icon">{{ childitems.smallTitle }}</span>
              <span class="sidebar-normal">{{ childitems.title }}</span>
            </a>
            <a
              (click)="childitems.isCollapsed = !childitems.isCollapsed"
              *ngIf="childitems.type === 'sub'"
              [attr.aria-controls]="childitems.collapse"
              [attr.aria-expanded]="!childitems.isCollapsed"
              data-toggle="collapse"
            >
              <span class="sidebar-mini-icon">{{ childitems.smallTitle }}</span>
              <span class="sidebar-normal">
                {{ childitems.title }}
                <b class="caret"></b>
              </span>
            </a>
            <div
              *ngIf="childitems.type === 'sub'"
              [collapse]="childitems.isCollapsed"
              [isAnimated]="true"
              class="collapse"
              id="{{ childitems.collapse }}"
            >
              <ul class="nav">
                <li
                  *ngFor="let childitem of childitems.children"
                  routerLinkActive="active"
                >
                  <a
                    [routerLink]="[
                      menuitem.path,
                      childitems.path,
                      childitem.path
                    ]"
                  >
                    <span class="sidebar-mini-icon">
                      {{ childitem.smallTitle }}
                    </span>
                    <span class="sidebar-normal">
                      {{ childitem.title }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
