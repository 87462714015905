import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-infobox',
  templateUrl: `./infobox.component.html`,
  styleUrls: [`./infobox.component.scss`],
})
export class InfoboxComponent {
  @Input() info;

  constructor() {}

  countDown() {
    setTimeout(() => (this.info.show = false), 5000);
  }
}
