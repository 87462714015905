import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Bank, BankCreate } from '@banks/interfaces/bank.interface';
import { environment } from '@env/environment';
import { BankAllModel } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  getBanks(query?: any) {
    let options = {};

    if (query) {
      let params = new HttpParams()
        .set('skip', query.skip.toString())
        .set('limit', query.limit.toString());

      if (query.bankName !== '') {
        params = params.set('bankName', query.bankName);
      }
      if (query.contactName !== '') {
        params = params.set('contactName', query.contactName);
      }

      if (query.classification.length > 0) {
        params = params.set('classification', query.classification);
      }
      if (query.territories.length > 0) {
        params = params.set('territories', query.territories);
      }
      if (query.bankListId !== '') {
        params = params.set('bankListId', query.bankListId);
      }
      options = { params };
    }

    return this.http.get(`${environment.API_URI}/banks`, options);
  }

  getBank(bankId: string) {
    return this.http.get(`${environment.API_URI}/banks/${bankId}`);
  }

  deleteBank(bankId: string) {
    return this.http.delete(`${environment.API_URI}/banks/${bankId}`);
  }

  createBank(bank: BankCreate) {
    return this.http.post(`${environment.API_URI}/banks`, bank);
  }

  updateBank(bank: Bank) {
    return this.http.patch(`${environment.API_URI}/banks`, bank);
  }

  getBankAll() {
    return this.http.get<{ postMessage: string; data: BankAllModel[] }>(
      `${environment.API_URI}/banks/all`,
    );
  }
}
