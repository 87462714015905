import { Pipe, PipeTransform } from '@angular/core';

import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(phone: any): any {
    try {
      const PNU = PhoneNumberUtil.getInstance();
      const NUMBER = PNU.parseAndKeepRawInput(phone, 'US');
      return PNU.format(NUMBER, PhoneNumberFormat.NATIONAL) || '';
    } catch (e) {
      return phone;
    }
  }
}
