import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import {
  BankList,
  BankListCreate,
  FilterBankList,
} from '@bankList/components/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class BankListsService {
  constructor(@Inject(HttpClient) private http: HttpClient) {}

  getLists(query?: FilterBankList) {
    let options = {};

    if (query) {
      let params = new HttpParams()
        .set('skip', query.skip.toString())
        .set('limit', query.limit.toString());

      if (query.bankListName != '') {
        params = params.set('bankListName', query.bankListName);
      }

      options = { params };
    }
    return this.http.get(`${environment.API_URI}/bank-list`, options);
  }

  getList(bankListId: string) {
    return this.http.get(`${environment.API_URI}/bank-list/${bankListId}`);
  }

  deleteList(bankListId: string) {
    return this.http.delete(`${environment.API_URI}/bank-list/${bankListId}`);
  }

  createList(data: BankList) {
    return this.http.post(`${environment.API_URI}/bank-list`, data);
  }

  updateList(data: BankListCreate) {
    return this.http.patch(`${environment.API_URI}/bank-list`, data);
  }

  getBankListByName(query?: any) {
    let options = {};

    if (query) {
      let params = new HttpParams().set('bankListName', query.bankListName);

      if (query.bankListName != '') {
        params = params.set('bankListName', query.bankListName);
      }

      options = { params };
    }
    return this.http.get(
      `${environment.API_URI}/bank-list/bank-list-name`,
      options,
    );
  }

  getBankListInDeal(companyId: string) {
    const params = new HttpParams().set('companyId', companyId);
    return this.http.get(`${environment.API_URI}/bank-list/bank-list-in-deal`, {
      params,
    });
  }
}
