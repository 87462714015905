import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { LoginDataModel } from '@core/models';
import { CookiesService } from './cookies.service';
import { User } from '@users/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User;
  private API_URI = environment.API_URI;

  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(Router) private router: Router,
    @Inject(CookiesService) private cookies: CookiesService,
  ) {
    this.user = this.getDataLogin().user;
  }

  saveToken(data: LoginDataModel) {
    this.cookies.setCookie({
      name: 'authApp',
      value: JSON.stringify(data),
      expireDays: 1,
    });
  }

  getToken(): LoginDataModel {
    const data = this.cookies.getCookie('authApp');
    localStorage.removeItem('authApp');
    return data ? JSON.parse(data) : false;
  }

  logOut() {
    localStorage.removeItem('authApp');
    this.cookies.deleteCookie('authApp');
    this.router.navigateByUrl('auth/login');
  }

  isLogged() {
    return this.getToken();
  }

  returnToken() {
    return this.isLogged ? this.getToken() : null;
  }

  getDataLogin(): LoginDataModel {
    const data = this.cookies.getCookie('authApp');
    localStorage.removeItem('authApp');
    return data ? JSON.parse(data) : false;
  }

  login(payload: { email: string; password: string }): Observable<any> {
    return this.http.post(`${this.API_URI}/auth/login`, payload).pipe(
      map((resp: any) => {
        if (resp.data) {
          this.user = resp.data.user;
          this.saveToken(resp.data);
        }
        return resp;
      }),
    );
  }
}
