import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  constructor() {}

  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  setCookie(params: any) {
    const expired = new Date();
    const year = expired.getFullYear();
    const mount = expired.getMonth() + 1;
    const day = expired.getDate() + (params.expireDays ? params.expireDays : 1);
    const temporalDate = new Date(`${year}/${mount}/${day}/`).getTime();
    expired.setTime(temporalDate);

    document.cookie =
      (params.name ? params.name : '') +
      '=' +
      (params.value ? params.value : '') +
      ';' +
      (params.session && params.session == true
        ? ''
        : `expires=${expired.toUTCString()};`) +
      'path=' +
      (params.path && params.path.length > 0 ? params.path : '/') +
      ';' +
      (location.protocol === 'https:' && params.secure && params.secure == true
        ? 'secure'
        : '');
  }

  deleteCookie(cookieName: string) {
    this.setCookie({ name: cookieName, value: '', expireDays: -2 });
  }
}
