<mat-dialog-content class="custom-modal-container">
  <h5 class="text-center text-black" mat-dialog-title>Information deal</h5>
  <div class="row">
    <div class="col-lg-12 text-center">
      <div class="row">
        <div class="col-md-12">
          <span class="text-black">Comment: {{ data.comment }} </span>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions class="text-black justify-content-center">
    <button
      (click)="close()"
      class="btn btn-block btn-sm btn-default text-white"
      mat-button
    >
      Close
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
